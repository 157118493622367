import { graphql, navigate } from 'gatsby';
import { object, shape } from 'prop-types';
import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';

import { Action, Paragraph, Separator, Subtitle, TextBlock, TextString, Title } from 'ui/components';
import { color } from 'ui/settings';
import { Fold, Helmet, Layout } from 'ui/partials';
import { setSpace, setType } from 'ui/mixins';

const Notes = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Note = styled.li`
  ${setSpace('pvm')};
  border-bottom: 1px solid ${({ theme }) => theme.decor};
  cursor: pointer;
  width: 100%;
  &:not(:last-child) {
    ${setSpace('mbx')};
  }
  ${Subtitle} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-family: inherit;
    }
  }
`;
const NoteDate = styled(TextString)`
  ${setType('x')};
  color: ${({ theme }) => theme.lowContrast};
  font-weight: normal;
`;

const NotesListing = props => {
  const { theme } = props;
  const { edges: notes } = props.data.allMarkdownRemark;
  return (
    <Fragment>
      <Helmet {...props} title="Articles" />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">Notebook</TextString>
              <TextString looks="h1">A collection of handy resources</TextString>
            </Title>
            <Paragraph looks="p2">
              Selection of design and development resources, things I found useful along the way.
            </Paragraph>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        <Notes>
          {notes.map(({ node: note }) => (
            <Note key={note.id} onClick={() => navigate(`/noted/${note.frontmatter.uid}`)} role="link" theme={theme}>
              <Subtitle looks="h5">
                <Action plain to={`/noted/${note.frontmatter.uid}`}>
                  {note.frontmatter.title}
                </Action>{' '}
                <NoteDate theme={theme}>{note.frontmatter.date}</NoteDate>
              </Subtitle>
            </Note>
          ))}
        </Notes>
      </Layout>
    </Fragment>
  );
};

export default withTheme(NotesListing);

NotesListing.propTypes = {
  data: shape({
    allMarkdownRemark: object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query AllNotesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//pages/noted/*/.*/*.md/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            date(formatString: "MMM YYYY")
            title
          }
        }
      }
    }
  }
`;
